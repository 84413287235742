import React, { FC } from "react";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import cn from "classnames";

import { TMarksColorProps, TMarksLinkProps } from "./block-content.types";
import { IComponentRawTextEditor } from "@src/types/page-components.types";

type Props = {
	content: IComponentRawTextEditor[];
	className?: string;
};

const builder = imageUrlBuilder({
	projectId: process.env.GATSBY_SANITY_PROJECT_ID as string,
	dataset: process.env.GATSBY_SANITY_DATASET as string,
});

const components = {
	block: {
		normal: ({ children }: any) => {
			if (typeof children === "object" && children[0] === "") return "\n";

			return <p>{children}</p>;
		},
		blockquote: ({ children }: any) => {
			return <blockquote>{children}</blockquote>;
		},
		h1: ({ children }: any) => <h1>{children}</h1>,
		h2: ({ children }: any) => <h2>{children}</h2>,
		h3: ({ children }: any) => <h3>{children}</h3>,
		h4: ({ children }: any) => <h4>{children}</h4>,
		h5: ({ children }: any) => <h5>{children}</h5>,
		h6: ({ children }: any) => <h6>{children}</h6>,
	},
	list: {
		bullet: ({ children }: any) => <ul>{children}</ul>,
		number: ({ children }: any) => <ol>{children}</ol>,
	},
	listItem: {
		bullet: ({ children }: any) => <li>{children}</li>,
		number: ({ children }: any) => <li>{children}</li>,
	},
	hardBreak: () => "\n",
	marks: {
		color: (props: TMarksColorProps) => {
			const { children, value } = props;
			const color = value?.hex;

			if (color) return <span style={{ color }}>{children}</span>;

			return children;
		},
		br: () => <br className="1" />,
		link: (props: TMarksLinkProps) => {
			const { children, value } = props;

			return (
				<a href={value?.href} target="_blank">
					{children}
				</a>
			);
		},
	},
	types: {
		image: (props: any) => {
			const asset = props?.value?.asset as any;
			if (!asset) return null;

			const { width, height, filename } = asset;

			const alt = filename.split(".")[0];
			const src = builder.image(asset).width(width).height(height).url();

			return (
				<div className="my-2">
					<img src={src} width={width} alt={alt} loading="lazy" />
					<p className="pt-3 text-[0.75rem] leading-4 text-center">{alt}</p>
				</div>
			);
		},
	},
};

const BlockContent: FC<Props> = (props) => {
	const { content, className } = props;

	return (
		<div className={cn("block-content", className)}>
			<PortableText value={content} components={components} />
		</div>
	);
};

export default BlockContent;
