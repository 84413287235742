import React, { FC } from "react";
import cn from "classnames";

import { Link } from "@src/components/ui";
import { IComponentLinkWithSubLinks } from "@src/types/page-components.types";

type Props = {
	content: IComponentLinkWithSubLinks;
};

const PageLink: FC<Props> = (props) => {
	const { content } = props;

	const { link, subLinks } = content;

	return (
		<>
			<Link target={link.target} className="block max-w-fit">
				<p
					className={cn(
						"mb-4 text-[0.9375rem] leading-6 tracking-[-0.5%] font-medium text-[#9EA2AC]",
						{ "hover:underline": link.target !== null },
					)}
				>
					{link.label}
				</p>
			</Link>
			{Boolean(subLinks.length) && (
				<ul>
					{subLinks.map((subLink) => {
						const { _key, label, target } = subLink;

						return (
							<li key={_key} className="max-w-fit mb-[0.375rem] last:mb-0">
								<Link target={target}>
									<p
										className={cn(
											"text-[0.8125rem] leading-6 tracking-[-0.5%] font-normal text-[#5B5F66]",
											{ "hover:underline": target !== null },
										)}
									>
										{label}
									</p>
								</Link>
							</li>
						);
					})}
				</ul>
			)}
		</>
	);
};

export default PageLink;
