import React, { FC, ReactNode } from "react";
import { PageProps } from "gatsby";

import { CookieBanner, Footer, Navigation } from "./components";
import { PageContextProvider } from "@src/context/page-context";
import { IPage, IPageContext } from "@src/types/pages.types";

type Props = {
	children: ReactNode;
	pageContext: IPageContext<IPage>;
} & PageProps;

const Layout: FC<Props> = (props) => {
	const { children } = props;

	return (
		<PageContextProvider state={props}>
			<div className="flex flex-col h-max min-h-screen selection:bg-primary selection:text-white">
				<Navigation />
				<main>{children}</main>
				<Footer />
				<CookieBanner />
			</div>
		</PageContextProvider>
	);
};

export default Layout;
