import React, { FC, ReactNode } from "react";

import { IPageState } from "./page.types";
import { PageContext } from "./page.context";

type Props = {
	state: IPageState;
	children: ReactNode;
};

const PageContextProvider: FC<Props> = (props) => {
	const { state, children } = props;

	return <PageContext.Provider value={{ state }}>{children}</PageContext.Provider>;
};

export default PageContextProvider;
