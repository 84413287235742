import { LOCALES } from "../constants/i18n.constants";
import {
	ICMSTranslationMetadata,
	ICMSTranslationMetadataTranslation,
	TSEOIdTranslations,
} from "@src/types/gatsby-node.types";
import { ISEO } from "@src/types/page-components.types";
import { IPage, ITranslatedPath } from "@src/types/pages.types";

// Transforms array of ICMSTranslationMetadataTranslation[] to object where key is SEO._id, and value is ICMSTranslationMetadataTranslation[]
// This transformation avoid nested array mapping when finding page translations
export const transformCMSTranslationMetadataToSEOIdTranslations = (
	cmsTranslationMetadata: ICMSTranslationMetadata,
) => {
	const { nodes } = cmsTranslationMetadata.data.allSanityTranslationMetadata;

	const seoIdTranslations: TSEOIdTranslations = {};

	nodes.forEach((node) => {
		node.translations.forEach((translation) => {
			const seoId = translation.value.seo?._id;

			if (seoId) seoIdTranslations[seoId] = node.translations;
		});
	});

	return seoIdTranslations;
};

export const createPagePath = (seo: ISEO) => {
	const { slug, parentPages } = seo;

	return (
		"/" +
		parentPages
			.map(({ slug }) => slug.trim())
			.concat(slug.trim())
			.filter(Boolean)
			.join("/")
	);
};

const constructTranslatedPaths = (
	translations: ICMSTranslationMetadataTranslation[],
	currentPageLocale: string,
): ITranslatedPath[] => {
	const translatedPaths = [] as ITranslatedPath[];

	translations.forEach((translation) => {
		const { language, seo } = translation.value;
		if (currentPageLocale === language) return;

		translatedPaths.push({ locale: language, path: createPagePath(seo) });
	});

	return translatedPaths;
};

// Note: this function will be called below only in case if CMS has 3+ locales
const findMissingTranslations = (
	missingLocales: string[],
	seoIdTranslations: TSEOIdTranslations,
	page: IPage,
) => {
	const { _id, displayName, language, seo } = page;

	const recursiveFindMissingTranslations = (
		missingLocales: string[],
		parentPageSEOIndex: number,
	): ICMSTranslationMetadataTranslation[] => {
		if (parentPageSEOIndex < 0) return [];

		const parentPageSEO = seo.parentPages[parentPageSEOIndex];
		const parentPageTranslations = seoIdTranslations[parentPageSEO?._id];

		const missingPageTranslations = parentPageTranslations?.filter((parentPageTranslation) =>
			missingLocales.includes(parentPageTranslation.value?.language),
		);

		if (missingPageTranslations && missingPageTranslations.length > 0) {
			return missingPageTranslations;
		}

		return recursiveFindMissingTranslations(missingLocales, parentPageSEOIndex - 1);
	};

	const missingPageTranslations = recursiveFindMissingTranslations(
		missingLocales,
		seo.parentPages.length - 1,
	);

	if (missingPageTranslations.length > 0) return missingPageTranslations;

	throw new Error(
		`Page (id: ${_id}, display name: ${displayName}, locale: ${language}) is missing some locale in "translatedPaths"`,
	);
};

export const createTranslatedPagePaths = (
	seoIdTranslations: TSEOIdTranslations,
	page: IPage,
): ITranslatedPath[] | null => {
	const { _id, displayName, language, seo } = page;

	// Recursive function which finds page translations or its parent page translations
	const recursiveFindTranslations = (
		SEO: ISEO,
		parentPageSEOIndex: number,
	): ICMSTranslationMetadataTranslation[] | undefined => {
		// Try to find page translation in seoIdTranslations by SEO._id
		const translations = seoIdTranslations[SEO?._id];

		if (translations) return translations;
		if (parentPageSEOIndex < 0) return;

		// If page has no translations:
		// Find parent page SEO and call recursive function to find parent page translations
		const parentPageSEO = seo.parentPages[parentPageSEOIndex];
		return recursiveFindTranslations(parentPageSEO, parentPageSEOIndex - 1);
	};

	const pageTranslations = recursiveFindTranslations(seo, seo.parentPages.length - 1);

	if (!pageTranslations) {
		// null is returned in case if we got only one default locale in CMS
		if (LOCALES.length === 1) return null;

		throw new Error(
			`Page (id: ${_id}, display name: ${displayName}, locale: ${language}) or its parent pages have no translations`,
		);
	}

	const translatedPaths = constructTranslatedPaths(pageTranslations, language);
	const translatedLocales = LOCALES.filter((locale) => locale !== language);

	// This check will not be passed until CMS got 3+ locales
	if (translatedPaths.length === translatedLocales.length) {
		return translatedPaths;
	}

	// Find which locales we missing in "pageTranslations"
	const missingLocales = translatedLocales.filter((translatedLocale) => {
		return translatedPaths.some((translatedPath) => translatedPath.locale !== translatedLocale);
	});

	// Recursive find missing locales in parent pages
	const missingPageTranslations = findMissingTranslations(missingLocales, seoIdTranslations, page);

	return constructTranslatedPaths([...pageTranslations, ...missingPageTranslations], language);
};
