import { createContext, useContext } from "react";

import { IPageContext } from "./page.types";

const PageContext = createContext<IPageContext | null>(null);

const usePageContext = (): IPageContext => {
	const context = useContext(PageContext);

	if (!context) {
		throw new Error("usePageContext must be used within PageProvider");
	}

	return context;
};

export { PageContext, usePageContext };
