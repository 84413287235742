import React, { FC } from "react";
import cn from "classnames";

import { Image, Link } from "@src/components/ui";
import {
	IComponentExternalCallToAction,
	IComponentLink,
	IWithKey,
} from "@src/types/page-components.types";

type Props = {
	content: {
		link: IComponentLink;
		socialMedia: (IComponentExternalCallToAction & IWithKey)[];
	};
};

const SocialMedia: FC<Props> = (props) => {
	const { content } = props;

	const { link, socialMedia } = content;
	const { target, label } = link;

	return (
		<div className="flex flex-col items-end lg:ml-8">
			<Link target={target}>
				<p
					className={cn(
						"mb-5 text-[0.9375rem] leading-6 tracking-[-0.5%] font-medium text-[#9EA2AC]",
						{ "hover:underline": target !== null },
					)}
				>
					{label}
				</p>
			</Link>
			<ul>
				{socialMedia.map((social) => {
					const { _key, url, startIcon, label, endIcon, id } = social;

					return (
						<li
							key={_key}
							className="group max-w-fit mb-4 last:mb-0 rounded-full p-[1px] bg-gradient-to-tr from-10% lg:from-20% from-[#202125] to-white/20"
						>
							<a
								{...(id !== null && { id })}
								href={url}
								className="block rounded-full p-[0.75rem] transition-colors duration-200 bg-[#202125] group-hover:bg-[#28292C]"
							>
								{startIcon !== null && (
									<Image CMSImage={startIcon} alt="contact" style={{ display: "block" }} />
								)}
								<p className="text-[0.8125rem] leading-6 tracking-[-0.5%] font-normal text-[#5B5F66]">
									{label}
								</p>
								{endIcon !== null && (
									<Image CMSImage={endIcon} alt="contact" style={{ display: "block" }} />
								)}
							</a>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default SocialMedia;
