import React, { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Address, Logo, PageLinks, PrivacyPolicy } from "./components";
import { DEFAULT_LOCALE } from "@src/constants/i18n.constants";
import { IFooterData } from "./footer.types";

const Footer: FC = () => {
	const { allSanityFooter } = useStaticQuery(graphql`
		{
			allSanityFooter {
				nodes {
					language
					logo {
						asset {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 75
										placeholder: BLURRED
										breakpoints: [480, 768, 1024, 1440]
										blurredOptions: { toFormat: WEBP }
										webpOptions: { quality: 75 }
									)
								}
								publicURL
								extension
							}
							width
							height
						}
					}
					addresses {
						_key
						street
						location
						href
					}
					pageLinks {
						_key
						link {
							label
							target {
								_id
								slug
								parentPages {
									_id
									slug
								}
							}
						}
						subLinks {
							_key
							label
							target {
								_id
								slug
								parentPages {
									_id
									slug
								}
							}
						}
					}
					contactUs {
						label
						target {
							_id
							slug
							parentPages {
								_id
								slug
							}
						}
					}
					socialMedia {
						_key
						url
						startIcon {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						label
						endIcon {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						id
					}
					copyright
					privacyPolicy {
						label
						target {
							_id
							slug
							parentPages {
								_id
								slug
							}
						}
					}
				}
			}
		}
	`) as IFooterData;

	const footer = allSanityFooter.nodes.find((node) => node.language === DEFAULT_LOCALE);

	if (footer === undefined) return null;

	const { logo, addresses, pageLinks, contactUs, socialMedia, copyright, privacyPolicy } = footer;

	return (
		<footer className="mt-auto bg-[#1A1B1F]">
			<div className="max-w-screen-xl m-auto pt-14 pb-8 px-4 lg:pb-12 lg:px-14">
				<div className="lg:flex">
					<div className="lg:w-2/5 lg:mr-8">
						<Logo content={logo} />
						<Address content={addresses} className="hidden lg:block" />
					</div>
					<PageLinks content={{ pageLinks, socialMediaLink: contactUs, socialMedia }} />
				</div>
				<Address content={addresses} className="mt-10 lg:hidden" />
				<PrivacyPolicy content={{ copyright, link: privacyPolicy }} />
			</div>
		</footer>
	);
};

export default Footer;
