import React, { FC } from "react";
import cn from "classnames";

type Props = {
	isOpen: boolean;
	onClick: () => void;
};

const BurgerMenu: FC<Props> = (props) => {
	const { isOpen, onClick } = props;

	const spanClass = "block h-0.5 w-6 rounded-sm transition-all ease-out bg-[#343330]";

	return (
		<button
			aria-label="Menu"
			onClick={onClick}
			className="lg:hidden flex flex-col justify-center items-center"
		>
			<span
				className={cn(
					spanClass,
					"duration-200",
					{ "rotate-45 translate-y-1": isOpen },
					{ "-translate-y-0.5": !isOpen },
				)}
			/>

			<span
				className={cn(
					spanClass,
					"duration-100 my-0.5",
					{ "opacity-0": isOpen },
					{ "opacity-100": !isOpen },
				)}
			/>

			<span
				className={cn(
					spanClass,
					"duration-200",
					{ "-rotate-45 -translate-y-1": isOpen },
					{ "translate-y-0.5": !isOpen },
				)}
			/>
		</button>
	);
};

export default BurgerMenu;
